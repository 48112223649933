.dash-subject-progress-container {
  width: 70vw;
}

.subjects-container {
  width: 80vw;
  display: flex;
  flex-direction: column;
  align-items: self-start;
  margin-top: 2rem;
  margin-left: 2em;
  overflow: hidden;
}

.program-card-container {
  width: 60%;
  display: flex;
  align-items: center;
  margin-top: 3rem;
  overflow: hidden;
  flex-wrap: wrap;
}

.subjects-container h3 {
  margin-left: 5rem;
  align-self: start;
  font-size: 0.8rem;
}

.subjects-and-add-courses {
  width: 85%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.subjects-heading {
  font-size: 1rem;
  font-weight: bold;
}

.add-courses {
  width: 12%;
  height: 2.5rem;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  font-size: 0.7rem;
  color: #349367;
  border: 1px solid #349367;
  border-radius: 5px;
  cursor: pointer;
  background-color: white;
}

.subject-item {
  display: flex;
  justify-content: space-between;
  border-radius: 20px;
  /* box-shadow: 2px 3px 5px rgba(0, 0, 0, 0.2); */
  /* padding: 1rem; */
  border: 2px solid rgba(0, 0, 0, 0.2);

  background-color: white;
}

.course-name {
  padding: 2rem;
  width: 27%;
}

.course-name h6,
h2 {
  margin: 0;
}

.progress-bar-and-course-container {
  width: 60%;
  background-color: #d6e9e1;
  padding: 2rem;
  border-radius: 0 20px 20px 0;
  position: relative;
  overflow: hidden;
}

.view-all-chapters {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 1em;
  color: #349367;
  margin-top: 1rem;
  text-decoration: none;
}

.view-all-chapters p {
  margin: 0;
  font-size: 0.6rem;
}

.view-all-chapters i {
  font-size: 0.5rem;
}

.course-title-and-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.course-title-and-actions button {
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  /* box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.2); */
  width: 20%;
  height: 100%;
  font-size: medium;
  padding: 0.8rem;
  cursor: pointer;
  z-index: 555;
}

/* .progress-bar-container {
  width: 100%;
  height: 1rem;
  background-color: #9cc9b4;
  position: absolute;
  left: 0;
  bottom: 0;
}

.progress-bar {
  width: 30%;
  height: 100%;
  background-color: #349367;
  border-radius: 0 20px 20px 0;
} */

/* background design */
.progress-circle-one {
  width: 5rem;
  height: 5rem;
  border: 10px solid #34936798;
  position: absolute;
  border-radius: 50%;
  top: -25%;
  right: 10%;
}

.progress-circle-two {
  width: 3rem;
  height: 3rem;
  background-color: #34936798;
  position: absolute;
  border-radius: 50%;
  bottom: -5%;
  left: 70%;
}
 
/* discover courses */

.discover-courses {
  width: 80vw;
  margin-left: 3rem;
  margin-top: 3rem;
  display: flex;
  align-items: center;
  gap: 2rem;
}

.discover-heading {
  width: 50%;
}

.discover-courses-container {
  width: 70vw;
  background-color: #d6e9e1;
  padding: 2rem;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  margin-bottom: 3rem;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  transform: translateX(4rem);
}

.program-card-container {
  width: 70vh;
  display: flex;
  gap: 1rem;
}

.right-arrow{
  width: 3rem;
  height: 3rem;
  font-size: 2.5rem;
  transform: translateX(-10rem);
  border-radius: 50%;
  padding: .5rem;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.program-card-container {
  width: 55vw;
}

.course-container {
  /* height: 20rem; */
  width: 230px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  box-shadow:5px 5px 10px #9cc9b4;
  cursor: pointer;
}
