.job-details-container {
  margin-left: 2rem;
  width: 75vw;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  gap: 1em;
}

.go-back {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 0.5em;
  cursor: pointer;
}

.job-img {
  width: 4rem;
  height: 4rem;
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.job-img img {
  width: 90%;
  height: 90%;
  object-fit: cover;
  overflow: hidden;
}

.job-details-and-descriptions {
  width: 100%;
  display: flex;
  gap: 2em;
}

.job-detail {
  width: 40%;
  height: 30rem;
  text-align: center;
  box-shadow: 1px 2px 10px lightgrey;
  border-radius: 10px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  gap: 1rem;
  background-color: white;
  position: sticky;
  top: 5rem;
}

.job-detail h2 {
  margin: 0;
}

.job-title-and-descripiton {
  width: 60%;
  border-radius: 20px;
  box-shadow: 1px 2px 10px lightgrey;
  padding: 10px;
}

.job-company-name {
  font-size: 1.5rem;
  color: #cd2626;
  text-transform: capitalize;
}

.job-location {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1em;
}

.job-location i {
  color: #349367;
}

.job-description {
  width: 85%;
  font-size: 0.9rem;
}

.job-description p {
  text-align: center;
  margin: 0;
}

.job-tags {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.job-tags h3 {
  padding: 5px 10px;
  background-color: #d6e9e1;
  border-radius: 10px;
  font-size: 0.8rem;
}

.visit-cmpny {
  width: 70%;
  border: 1px solid #349367;
  border-radius: 10px;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.visit-cmpny:hover{
  background-color: #3fa959;
  color: white;
}

.visit-cmpny link {
  margin: 0;
  color: #3fa959;
  text-decoration: none;
}

a {
  /* color: #3fa959; */
  text-decoration: none;
  font-weight: bold;
}

/* job details right container styling  */
.jobs-complete-details {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 1rem;
}
.job-matching{
  width: 100%;
  /* display: flex; */
  justify-content: center;
  justify-items: center;
  align-items: center;
}

.job-title-and-descripiton {
  width: 80%;
  background-color: white;
  margin-bottom: 1rem;
}

.job-title-and-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title h2 {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 0;
}

.title p {
  font-size: 1rem;
  font-weight: 100;
  margin-top: 0;
}

.job-action-btn {
  display: flex;
  /* width: 45%;
    justify-content: space-around; */
  align-items: center;
  gap: 1rem;
}

.unlock {
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 0.5em;
  border-radius: 10px;
  color: white;
  background-color: #3fa959;
  padding: 0 1.5rem;
  height: 2.5rem;
  cursor: pointer;
  font-size: 0.7rem;
}

.save-job {
  border: 1px solid #3fa959;
  padding: 0 1.5rem;
  border-radius: 10px;
  height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.7rem;
  cursor: pointer;
}

.jobs-descript h2,
p {
  text-align: start;
}

.qualifications,
.responsibilities li {
  font-size: 0.8rem;
}
