.program-container {
  width: 80vw;
  font-family: "Raleway", sans-serif;

  /* border: 1px solid;
  /* width: 80%; */
  /* display: flex;
    flex-direction: column;
    justify-content: center; */
}
.title-image-card {
  display: flex;
  border: 1px solid;
  background-color: #f4f7f2;
  width: 70%;
  margin: 0 10em;
  border-radius: 20px;
  height: 300px;
}
.title-card {
  flex: 1 1;
  margin: 0;
  border-radius: 20px;
  padding: 1em;
  width: 55%;
  background-color: #f4f7f2;
  font-weight: 900;
  font-size: 16px;
  color: darkblue;
  /* display: flex; */
  align-items: center;
  justify-content: center;
}
/* .title-card h1 {
  padding: 1em;
} */
.title-card > img {
  width: 20%;
  margin-left: 2em;
}

.program-img {
  margin: 0 0;
  padding: 0;
  flex: 1 1;
  width: 40%;
  /* width: 60vw; */
  border-radius: 20px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.program-img img {
  max-width: 100%;

  object-fit: cover;
  margin: 1rem;
}

.course-title-and-join {
  width: 70%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.prgrm-title {
  font-size: 22px;
}

.program-desc {
  width: 70%;
  /* border: 1px solid; */
}

.program-desc-para {
  width: 80%;
  text-align: start;
  font-size: 14px;
}

.logo-and-instructor img {
  width: 50px;
}

.logo-and-instructor {
  width: 70%;
  /* border: 1px solid; */
}

.logo-inst {
  /* border: 1px solid; */
  display: flex;
  justify-content: start;
  align-items: center;
  transform: translateX(-12px);
}

.logo-inst p {
  font-size: 12px;
  text-decoration: underline;
}

.program-details {
  width: 70%;
}

.program-duration-fees {
  /* border: 1px solid; */
  width: 40%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.learning-container {
  border: 1px solid;
  border-radius: 20px;
  width: 70%;
  display: flex;
  justify-content: center;
  align-items: start;
  overflow: hidden;
}

.learning-box {
  width: 90%;
  display: flex;
  flex-direction: column;
}

.learning-list ul {
  /* border: 1px solid; */
  display: grid;
  flex-wrap: wrap;
  width: 85%;
  grid-template-columns: 1fr 1fr;
  gap: 0.2rem;
}

.learning-list ul li {
  list-style: none;
  margin-bottom: 2rem;
  font-size: 14px;
  /* width: 50%; */
}

.learning-list ul li::before {
  content: "\2022"; /* Add content: \2022 is the CSS Code/unicode for a bullet */
  color: #3fa959; /* Change the color */
  font-size: 20px;
  text-align: center;
  font-weight: bold; /* If you want it to be bold */
  display: inline-block; /* Needed to add space between the bullet and the text */
  width: 1em; /* Also needed for space (tweak if needed) */
  margin-left: -1em; /* Also needed for space (tweak if needed) */
}

.learning-list {
  position: relative;
}

/* .girl-svg {
  position: absolute;
  transform: rotateY(45deg);
  transform-origin: center;
  top: -169px;
  left: -50px; */
/* } */

.girl-svg img {
  width: 139px;
  position: absolute;
  bottom: -25px;
  right: -49px;
  transform: rotateY(176deg);
}

.skills-gain {
  width: 70%;
  margin-top: 2rem;
}

.skills-tags {
  border: 1px solid;
  width: 6rem;
  height: 2rem;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  font-size: 14px;
  background-color: #f5f0ff;
  font-size: 10px;
}

/* responsive design */

@media (max-width: 850px) {
  .title-image-card {
    border: 1px solid;
    background-color: #f4f7f2;
    margin: 0 10em;
    border-radius: 20px;
    /* height: 200px; */
  }
  .title-card {
    margin: 0;
    border-radius: 20px 0px 0xp 20px;
    padding: 1em;
    background-color: #f4f7f2;
    font-weight: 900;
    font-size: 16px;
    color: darkblue;
    display: none;
  }

  .program-img {
    margin: 0 0;
    padding: 0;
    width: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .program-img img {
    max-width: 100%;
    /* height: 100vh; */
    /* background-repeat: repeat; */
    object-fit: cover;
  }

  .program-duration-fees {
    /* border: 1px solid; */
    width: 60vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .course-title-and-join {
    width: 90vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .prgrm-title {
    font-size: 1.2;
  }

  .program-desc {
    width: 90vw;
    /* border: 1px solid; */
  }

  .logo-and-instructor img {
    width: 50px;
  }

  .logo-and-instructor {
    width: 90vw;
    /* border: 1px solid; */
  }

  .logo-inst {
    /* border: 1px solid; */
    display: flex;
    justify-content: start;
    align-items: center;
    transform: translateX(-12px);
  }

  .logo-inst p {
    font-size: 0.8rem;
    text-decoration: underline;
  }

  .program-desc-para {
    width: 90vw;
    text-align: start;
    font-size: 14px;
  }

  .program-details {
    width: 90vw;
  }

  .learning-container {
    border: 1px solid;
    border-radius: 20px;
    width: 90vw;
    display: flex;
    justify-content: center;
    align-items: start;
    overflow: hidden;
  }

  .learning-box {
    width: 90%;
    display: flex;
    flex-direction: column;
  }

  .learning-list ul {
    /* border: 1px solid; */
    display: flex;
    gap: 0;
  }

  .learning-list ul li {
    list-style: none;
    font-size: 0.7rem;
    margin-bottom: 1rem;
  }

  .learning-list ul li::before {
    content: "\2022"; /* Add content: \2022 is the CSS Code/unicode for a bullet */
    color: #3fa959; /* Change the color */
    font-size: 20px;
    text-align: center;
    font-weight: bold; /* If you want it to be bold */
    display: inline-block; /* Needed to add space between the bullet and the text */
    width: 1em; /* Also needed for space (tweak if needed) */
    margin-left: -1em; /* Also needed for space (tweak if needed) */
  }

  .learning-list {
    position: relative;
  }

  .skills-gain {
    width: 90vw;
    margin-top: 2rem;
  }

  .skill-shortcuts {
    width: 90vw;
  }
}
