.progress-bar-container {
  width: 100%;
  height: .5rem;
  background-color: #9cc9b4;
  /* position: absolute; */
  left: 0;
  bottom: 0;
  overflow: hidden;
  
}
