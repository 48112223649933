.dash-header-container {
  /* width: 100vw; */
  display: flex;
  justify-content: center;
  position: sticky;
  top: 0;
  z-index: 5555;
  backdrop-filter: blur(2px);
}

.dash-header {
  width: 70%;
  height: 4.5rem;
  background-color: #3fa959;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 3rem;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  overflow: hidden;
}

.user-img-and-name {
  /* width: 7rem; */
  height: 50%;
  border: 1px solid;
  display: flex;
  justify-content: space-around;
  border-radius: 15px;
  gap: 1rem;
  background-color: white;
  padding: 5px;
  align-items: center;
  cursor: pointer;
}

.user-profile-image {
  align-self: baseline;
}

.user-name {
  position: relative;
}

.ring {
  position: absolute;
  width: 100px;
  height: 100px;
  border: 10px solid #8ce2a2be;
  border-radius: 50%;
  top: -5rem;
  left: -2rem;
}

.header-circle {
  position: absolute;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #9cc9b4;
  top: -1.5rem;
  right: 40%;
}

/* Navbar styling */
.dash-nav-container {
  /* position: sticky; */
  width: 14rem;
  /* border: 1px solid; */
  height: 85vh;
  border: 1px solid rgba(0, 0, 0, 0.2);
  /* box-shadow: 2px 2px 5px rgb(88, 93, 94); */
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
  margin-left: 1rem;
  overflow: hidden;
  position: sticky;
  top: 5rem;
  background-color: white;
  margin-bottom: 1rem;
  margin-top: 2rem;
}



.all-dash-nav-link {
  height: 80%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.4rem;
  padding: 0.4rem;

  justify-content: start;
  align-items: center;
  /* margin-top: 1rem; */
}

.dash-navs {
  width: 80%;
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  justify-content: start;
  gap: 0.5rem;
  border-radius: 5px;
  padding: 0.4rem;
  cursor: pointer;
}


.dash-navs a{
  font-size: large;
  font-weight:800;

}

.dash-navs:hover {
  background-color: #d6e9e1;
  font-weight: bold;
}



.active-link {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: start;
  border-radius: 5px;
  padding: 0.4rem ;
  padding-top: 0.6rem;
  background-color: #d6e9e1;
  /* flex-direction: column; */
}

.dash-ad {
  width: 90%;
  height: 7rem;
  border-radius: 20px;
  background-color: #3fa959;
  /* position: absolute; */
  bottom: 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 0.5em;
  margin-bottom: 0.5rem;
}

.ad-dash-in {
  width: 80%;
  color: white;
  /* padding-left: 1rem; */
}

.ad-dash-in h4 {
  font-size: 0.8rem;
}

.dash-join {
  background-color: white;
  color: black;
  border-radius: 20px;
  height: 1.8rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.8rem;
  font-weight: bold;
}

.dash-ad-img img {
  max-width: 5em;
  margin: 2rem 0;
}

.byte-logo {
  /* position: absolute; */
  top: 2rem;
  margin-top: 2rem;
}
.byte-logo:hover {
  cursor: pointer;
}

.byte-logo img {
  max-width: 8em;
  /* left: 1.5rem; */
}

/* profile box */
.user-profile-box {
  position: absolute;
  width: 15rem;
  /* height: 20rem; */
  border: 1px solid rgba(0, 0, 0, 0.2);
  top: 3rem;
  right: 14%;
  z-index: 2222;
  border-radius: 20px;
  background-color: white;
  display: flex;
  justify-content: center;
}

.profile-navs {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  gap: 0.6rem;
  margin: 1rem 0;
}

.profile-navs p {
  margin: 0;
  cursor: pointer;
}

.user-navs {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  gap: 0.7rem;
}

.user-navs h4 {
  margin: 0;
  text-align: start;
}

.user-img-and-name {
  display: flex;
  align-items: center;
  justify-content: space-around;

}

.user-img-and-name h2 {
  width: 80%;
  font-size: 1.2rem;
  margin: 0;
}

.heart-book-img img {
  width: 10rem;
  margin-bottom: 2rem;
}

.user-profile-image {
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 20px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: auto;
}

.user-profile-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
