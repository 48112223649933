.jobs-container {
  width: 80%;
  margin-left: 2rem;
  margin-top: 2rem;
}

.jobs-header-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.job-search-and-save {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.job-search {
  width: 20rem;
  height: 3rem;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  text-align: center;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: white;
}

.job-search input {
  outline: none;
  border: none;
  font-size: 1rem;
  width: 80%;
}
.saved-jobs{
  font-size: 1.3em;
}
.saved-jobs:hover{
  cursor: pointer;
  
}

.bookmark{
  cursor: pointer;
}

.bookmark i {
  margin-right: 0.5rem;
}

.jobs-filter {
  width: 20%;
  height: 100%;
  border: 1px solid rgba(0, 0, 0, 0.2);
  /* background-color: rgba(0, 0, 0, 0.05); */
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: sticky;
  /* top: 5rem; */
  margin-bottom: 1em;
}

.jobs-filter-options {
  align-self: start;
  margin-bottom: 1em;

}

.jobs-filter-options p {
  text-align: start;
  padding-left: 2rem;
  font-weight: bold;
  
}

.jobs-and-filter {
  /* width: 80vw; */
  margin-top: 2rem;
  display: flex;
  justify-content: space-around;
}
.filter{
  margin-top: 1em;
}
.checkboxes {
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: start;
  margin-left: 2rem;
  font-size: 1rem;
  padding: .4em;
}

.checkboxes input {
  width: 2rem;
  height: 1.3rem;
  accent-color: #349367;
}

.checkboxes label {
  justify-content: start;
}

.all-jobs {
  width: 80vw;
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
  gap: 1em;
  margin-left: 2rem;
  margin-bottom: 1rem;
}

.single-job-container {
  width: 31%;
  height: 20rem;
  border: 1px solid rgba(0, 0, 0, 0.2);

  /* border: 1px solid rgba(121, 121, 121, 0.2); */
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border-radius: 10px;
  padding: 10px;
  background-color: #ffffff;
  justify-content: space-between;
}

.job-logo {
  width: 4rem;
  height: 4rem;
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.job-logo img {
  width: 90%;
  height: 90%;
  object-fit: cover;
  border-radius: 10px;
}

.jobs-description {
  font-size: 0.8rem;
  margin-bottom: 0;
}

.job-logo-and-company {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 1rem;
}

.jobCmpnyName {
  font-size: 1rem;
  text-transform: capitalize;
}

.jobTitle {
  font-size: 1rem;
}

.job-tags h4 {
  border-radius: 10px;
  background-color: #d6e9e1;
  font-size: 0.7rem;
  padding: 8px;
  margin: 0;
  color: #158620;
}

.job-card-btn {
  display: flex;
  width: 100%;
  gap: 2rem;
  cursor: pointer;
  font-size: 0.8rem;
}

.apply-job-btn {
  background-color: #3fa959;
  color: white;
  border-radius: 10px;
  padding: 0.5rem;
  width: 45%;
  text-align: center;
  /* height: 1rem; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.save-job-btn {
  background-color: white;
  border: 2px solid #3fa959;
  border-radius: 10px;
  padding: 0.5rem;
  width: 45%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 1rem; */
}
