.blog-recommend {
  /* width: 70vw; */
  display: flex;
  justify-content: space-between;
  margin: 1rem 0;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  overflow: hidden;
  background-color: white;
  cursor: pointer;
}

.dash-blog-img {
  width: 50%;
}

.dash-blog-img img {
  max-width: 100%;
  height: 100%;
  object-fit: cover;
}

.dash-details-and-new {
  width: 60%;
  display: flex;
  justify-content: start;
  align-items: center;
}

.dash-blog-container {
  width: 80%;
}

.dash-blog-detail {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem 0;
}

.dash-blog-detail h2 {
  font-size: 1.2rem;
  font-weight: bold;
}

.dash-new-show {
  width: 20%;
  text-align: center;
  margin-top: 2rem;
}

.dash-new-show h2 {
  font-size: 0.8rem;
  background-color: #34936780;
  border-radius: 20px;
  padding: 0.5rem;
  width: 5rem;
  box-shadow: 4px 4px 5px rgba(0, 0, 0, 0.2);
}

.dash-authot-details {
  display: flex;
}

.dash-blog-likes-and-comment {
  display: flex;
  gap: 2rem;
}

.name-and-date h6 {
  margin: 0;
}
