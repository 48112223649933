.App {
  /* min-width: 100%;
  margin: 0;
  text-align: center; */
  }


  video{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }