.live-classes-container {
  width: 80vw;
  margin-left: 2rem;
}

.live-classes-and-mentor-container {
  display: flex;
  align-items: start;
  gap: 1rem;
}

.ongoing-and-recordings {
  width: 70%;
  margin-bottom: 1rem;
}

.ongoing-session {
  background-color: white;
  padding: 1rem;
  border: 2px solid rgba(0, 0, 0, 0.2);

  /* box-shadow: 3px 3px 5px grey; */
  border-radius: 20px;
}

.ongoing-classes {
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 1rem;
  background-color: #d6e9e1;
  padding: 1rem 1rem;
  border-radius: 20px;
  margin: 0.5rem;
  position: relative;
  overflow: hidden;
}

.orange-line {
  position: absolute;
  width: 1.5%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #f5eb97;
}

.class-heading {
  font-size: 0.9rem;
  font-weight: bold;
}

.class-description {
  font-size: 0.6rem;
}
.classes-details {
  width: 45%;
  display: flex;
  flex-direction: column;
  gap: .5rem;
}

.class-poster {
  /* height: 100%; */

  overflow: hidden;
}

.class-poster img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 20px;
}

.live-join-btn {
  padding: 0.5rem;
  background-color: white;
  color: #349367;
  border-radius: 20px;
  text-align: center;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.4);
  font-size: 0.8rem;
  cursor: pointer;
}

.class-join-and-time p {
  margin-top: 1rem;
  color: #349367;
  font-weight: bold;
  text-align: center;
}

.class-lang p {
  width: 4rem;
  padding: 0.1rem;
  background-color: white;
  border-radius: 20px;
  text-align: center;
  font-size: 0.68rem;
  font-weight: bold;
}

/* recording container */

.view-all-btn {
  cursor: pointer;
}

.recording-and-view-all {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.recording-classes {
  background-color: white;
  padding: 1rem;
  border: 2px solid rgba(0, 0, 0, 0.2);

  /* box-shadow: 3px 3px 5px grey; */
  border-radius: 20px;
  margin-top: 1rem;
}

.recording-classes-list {
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 1rem;
  background-color: #d6e9e1;
  padding: 1rem 1rem;
  border-radius: 20px;
  margin: 0.5rem;
  position: relative;
  overflow: hidden;
}

.recording-class-heading {
  font-size: 0.9rem;
  font-weight: bold;
}

.recording-description {
  font-size: 0.6rem;
}

.watch-video {
  width: 75%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 0.8rem;
  text-align: center;
  font-size: 0.6rem;
  color: black;
  border: 1px solid #349367;
  border-radius: 5px;
  cursor: pointer;
  background-color: white;
}

.watch-video p {
  font-size: 0.7rem;
  margin: 0;
  color: black;

}

/* mentor details */

.mentor-details {
  background-color: white;
  width: 25%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 1rem;
  border-radius: 20px;
  position: sticky;
  top: 4rem;
  margin-bottom: 1rem;
}

.mentor-heading {
  align-self: baseline;
  margin: 1rem;
  font-size: 1rem;
  font-weight: bold;
}

.mentor-profile-pic {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  /* overflow: hidden; */
  border: 3px solid #349367;
  position: relative;
}
.online-mark {
  position: absolute;
  width: 15px;
  height: 15px;
  background-color: #158620;
  border-radius: 50%;
  bottom: 0;
  top: 10%;
  right: 7%;
}

.mentor-profile-pic img {
  border-radius: 50%;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.mentor-complete-detail {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
}

.mentor-name-and-profession {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 0.5rem;
}

.mentor-name-and-profession h5 {
  margin-top: 1rem;
  margin-bottom: 0;
  font-size: 1rem;
}

.mentor-name-and-profession p {
  color: #349367;
}

.mentor-intro {
  text-align: center;
}

.mentor-social-link {
  width: 20%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  align-self: center;
}

.mentor-social-link i {
  cursor: pointer;
}

.contact-and-book {
  display: flex;
  gap: 1rem;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
}

.contact-and-book p {
  font-size: 0.7rem;
}

.contact-me,
.book-session {
  /* width: 60%; */
  display: flex;
  gap: 0.5rem;
  padding: 0.5rem;
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  cursor: pointer;
}

.contact-me {
  border: 1px solid #349367;
  color: #349367;
}

.book-session img {
  max-width: 0.9rem;
}

.contact-me img {
  max-width: 0.9rem;
}

.book-session {
  background-color: #2f2e418c;
  color: black;
}
