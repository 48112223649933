.all-recordings-container {
  width: 80vw;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
}

.recording-container {
  width: 95%;
  margin: 1rem 0;
}

.recording-back {
  margin-left: 2rem;
  cursor: pointer;
}

.all-recording-heading {
  margin-left: 2rem;
  font-size: 1rem;
  margin-top: 0.5rem;
}

.recording-card {
  width: 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 20px;
  padding: 1rem 0.5rem;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.2);
  background-color: white;
  cursor: pointer;
  margin: 0.5rem;
}

.recordings-card-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  gap: 1em;
  margin: 1rem;
}

.attended {
  color: #3fa959;
  font-weight: bold;
  margin-top: 0.5rem;
}

.recording-thumbnail {
  width: 95%;
  overflow: hidden;
  border-radius: 20px;
}

.recording-thumbnail img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.recording-name-and-duration {
  align-self: start;
  margin-left: 1rem;
}

.recording-name {
  font-size: 1rem;
  margin-bottom: 0.5rem;
}

.verify-icon img {
  max-width: 1rem;
}

.recording-duration {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  color: grey;
}

.recording-date {
  display: flex;
  gap: 0.5rem;
}

.recording-time {
  display: flex;
  gap: 0.5rem;
  margin-bottom: 1rem;
}
