.my-dashboard {
  width: 85vw;
  height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: end;
  align-self: center;
}

.dashboard-intro {
  width: 80%;
  height: 55%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 5rem;
  gap: 1rem;
}
.dashboard-intro h2 {
  font-size: 2rem;
  color: #3fa959;
  font-weight: bold;
}

.dashboard-intro p {
  text-align: center;
}

.start-learning {
  border-radius: 20px;
  background-color: #3f3d56;
  color: white;
  padding: 0.5rem 1rem;
  cursor: pointer;
  font-size: 0.8rem;
}

.recommendations {
  display: flex;
  gap: 1rem;
  /* height: 100%; */
  align-items: end;
  justify-content: center;
  margin-bottom: 0.5rem;
}

.recommendation {
  display: flex;
  width: 15rem;
  height: 3rem;
  background-color: #34936733;
  border-radius: 20px;
  justify-content: space-between;
  align-items: center;
  /* margin-top: 1rem; */
  padding: 0.5rem;
  cursor: pointer;
}

.recommendation p {
  width: 80%;
  font-size: 0.6rem;
}

.recommendation img {
  max-width: 1.5vw;
}

.input-and-send {
  width: 55vw;
  display: flex;
  justify-content: space-around;
  align-items: center;
  /* margin-top: 2rem; */
  align-self: center;
}

.searching {
  width: 50vw;
  height: 2.5rem;
  border: 1px solid;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  overflow: hidden;
  background-color: #62b38d;
}

.searching input {
  width: 95%;
  height: 100%;
  border: none;
  outline: none;
  font-size: 1.2rem;
  background-color: rgba(52, 147, 103, 0.17);
  /* color: white; */
}

::placeholder {
  color: black;
}

.searching img {
  max-width: 1.5rem;
  height: 100%;
}

.send {
  background-color: #349367;
  border-radius: 10px;
  padding: 0.7rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.send img {
  color: white;
  max-width: 1.3rem;
  cursor: pointer;
}

/* if the user enrolled data is shown then the dasah board design */
.enrolled-progress {
  width: 75vw;
  margin-left: 2rem;
  margin-top: 2rem;
}

.progress-card-container {
  width: 90%;
  display: flex;
  justify-content: start;
  gap: 2rem;
}

.progress-card {
  width: 15rem;
  height: 16rem;
  background-color: white;
  border-radius: 20px;
  padding: 0.5rem;
  cursor: pointer;
  border: 1px solid black;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.prog-course-name-percent {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.progress-peercent {
  width: 2rem;
  height: 2rem;
  border: 1px solid #3fa959;
  background-color: #d6e9e1;
  border-radius: 50%;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}

.progress-course-img {
  width: 100%;
  height: 50%;
  overflow: hidden;
}

.progress-course-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.course-progress-bar {
  width: 100%;
  height: 1rem;
  background-color: #d6e9e1;
  border-radius: 20px;
  margin-top: 1rem;
  position: relative;
  margin-bottom: 1rem;
}

.course-progress {
  width: 40%;
  height: 100%;
  background-color: #349367;
  position: absolute;
  top: 0;
  border-radius: 20px;
}

/* shortlisted jobs */

.Jobs{
  margin-top: 3em;
}

.job-shortlisted {
  width: 90%;
  background-color: white;
  padding: 0.5rem;
  border-radius: 20px;
  margin-bottom: 2rem;
}

.job-shortlist-container {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin-top: 1rem;
}

.job-shortlist-card-header {
  width: 95%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  align-self: center;
  margin-bottom: 0.5rem;
}

.job-shortlist-card-header th{
  /* margin: 0 2em; */
  width: 100%;

}

.job-shortlist-card {
  width: 95%;
  height: 6em;
  display: flex;
  gap: .3rem;
  justify-content: space-between;
  align-items: center;
  padding: 0.4rem;
  background-color: #d6e9e1;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  align-self: center;
  
}
.job-shortlist-card p {
  font-size: 16px;
}

.job-shortlist-card:hover{
  cursor: pointer;
}

.shortlist-logo {
  width: 3rem;
  height: 3rem;
  border-radius: 10px;
  padding: .4em;
  margin: .4em;
  overflow: hidden;
  border: 1px solid rgba(0, 0, 0, 0.2);

}

.shortlist-logo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.job-log-and-name {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.shortlist-status {
  width: 20%;
  text-align: start;
}

.view-all-jobs {
  cursor: pointer;
}

.applied-and-all {
  display: flex;
  width: 95%;
  justify-content: space-between;
  margin-top: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
}
