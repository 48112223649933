/* CandidateForm.css */

/* .candidate-form-container {
  width: 65vw;

  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
}

.candidate-form {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.candidate-form label {
  font-weight: bold;
}

.candidate-form input,
.candidate-form textarea {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
}

.candidate-form button {
  padding: 10px 16px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.candidate-form button:hover {
  background-color: #0056b3;
} */

.candidate-form-container {
  width: 100vw;
  margin-left: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.profile-routes {
  width: 100%;
}


