
.dashboard-main-container{
    font-family: 'Poppins', sans-serif;
}



.dashboard{
    display: flex;
    flex-direction: row;
}

.side-nav-bar{
    background-color: black;
    color: white;
}

.subject{
    width: 4em;
    height: 4em;
    background-color: green;
    margin: 1em;

}
.subject :hover{
    cursor: pointer;
}
.dashboardHeader{
    padding-top: 2em;
    background-color: green;
}

.dashboard-content{
    min-height: 100%;
}