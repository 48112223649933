
.chapters-container{
    margin-top: 2rem;
    margin-left: 2rem;
}

.chapters-detailed-container{
    width: 70vw;
    border-radius: 20px;
    /* box-shadow: 3pox 3px 5px rgba(0, 0, 0, .2); */
    border: 1px solid rgba(0, 0, 0, 0.2);

    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 1rem 0;
    cursor: pointer;
    
}

chapters-detailed-container :hover{
    
}

.chapter-name-and-open{
    width: 95%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1em;
}

.chapter-icon-and-details{
    display: flex;
    align-items: center;
    gap: 1rem;
}

.chapter-icon{
    width: 2rem;
    height: 2rem;
    background-color: #D6E9E1;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: .3em;
}

.chapter-icon img{
    width: 70%;
    height: 70%;
    object-fit: cover;
}

.chapter-name-and-title h3, p{
    margin: 0;
    font-size: 1rem;
}

.open-chapter{
    cursor: pointer;
}

.chapters-type{
    width: 85%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
    position: relative;
}



.bulb-img{
    position: absolute;
    left: -3rem;
    top: 10%;
}

.globe-img{
    position: absolute;
    left: -3rem;
    top: 50%;
}

.bulb-img  img{
    width: 2rem;
}

.globe-img img{
    width: 2.5rem;
}


.chapter-type-and-icon{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid #3F3D56;
    padding: 2rem 3rem;
    border-radius: 20px;
    gap: 1rem;
}
.chapter-type-img{
    width: 2rem;
    height: 2rem;
    background-color: #D6E9E1;
    border-radius: 50%;
    padding: 1rem;
}

.chapter-type-img img{
    max-width: 2em;
}